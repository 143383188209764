<template>
  <div class="basic-data-list">
    <query-view
      ref="myQueryView"
      name="codeOrName"
      :value.sync="baseFormModel.codeOrName"
      :placeholder="$t('lang.gles.common.pleaseInputCodeOrName')"
      :query-list="moreQueryData"
      @baseSearch="handleBaseSearch"
      @moreSearch="handleMoreSearch"
    />
    <el-card class="table-card">
      <div class="operator-wrapper">
        <el-button type="primary" icon="el-icon-plus" @click="updateCom">
          {{ $t("lang.gles.common.addNew") }}
        </el-button>
        <!-- 列设置 -->
        <!-- <column-config :list.sync="columnConfigList" /> -->
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
        @editItem="editItem"
        @delItem="delItem"
        @pageChange="pageChange"
      />
    </el-card>
  </div>
</template>
<script>
import ColumnConfig from "@/components/columnConfig";
import QueryView from "@/components/moreQuery/queryView.vue";
import MTable from "@/libs_sz/components/MTable/MTable";
import { getMoreQueryFormData, getSearchTableItem } from "../data";
import * as types from "@/store/mutationTypes";
import listMixins from "@/mixins/listMixins";
import { mapState, mapActions } from "vuex";
export default {
  name: "MaterialConfigList",
  components: {
    ColumnConfig,
    QueryView,
    MTable,
  },
  mixins: [listMixins],
  data() {
    return {
      tableData: [],
      baseFormModel: {
        codeOrName: "",
      },
      formModel: {},
      pageData: {
        currentPage: 1,
        pageSize: 10,
      },
      detailRow: {},
      detailDialogVisible: false,
      options: {},
      // 工位位置
      workPositionList: [],
      conutKey: 1,
    };
  },
  computed: {
    ...mapState("materialManage", [
      "materialPlaceList",
      "materialTypsList",
      "materialCodeList",
    ]),
    // 更多查询列表
    moreQueryData() {
      return getMoreQueryFormData(this, { ...this.formModel });
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      const methods = {
        linefeed: this.linefeed,
      };
      return getSearchTableItem(this, methods);
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem;
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        );
      }
      return list;
    },
  },
  created() {
    this.doSearch(this.baseFormModel);
    this.initSelectList();
  },
  methods: {
    ...mapActions("materialManage", ["getMaterialmaterialCodeList"]),
    /**
     * 初始化下拉选项
     */
    initSelectList() {
      this.getMaterialmaterialCodeList();
    },
    /**
     * 根据工位类型 获取位置
     */
    handleWorkPositionType(val, formItem) {
      this.workPositionList = [];
      // 防止触法 级联组件options 数据 报错
      this.$set(this.formModel, "workPositionKey", this.conutKey++);
      this.$set(this.formModel, "workPositionId", []);
      this.$set(this.formModel, formItem.name, val);
      if (!val) {
        return;
      }
      this.getWorkPositionByType({ type: val });
    },
    /**
     * 获取工位位置
     */
    async getWorkPositionByType(params) {
      const { data, code } = await this.$httpService(
        this.$apiStore.materialManage("getMaterialConfigMoreList"),
        params
      );
      if (code !== 0) {
        return;
      }
      const list = data ?? [];
      const workPositionListd = list.map((item) => {
        // 工位位置
        let value = "";
        let label = "";
        let children = [];
        if (params.type === 1) {
          value = `${item.areaId},${item.productionLineId},${item.deviceId}`;
          label = item.deviceName;
        } else if (params.type === 2) {
          value = `${item.areaId}`;
          label = item.areaName;
        }
        children = (item?.workPositions || []).map((t) => {
          return {
            value: `${t.workPositionId},${t.workPositionCode}`,
            label: t.workPositionCode,
            // disabled: !!t.exist,
          };
        });
        return {
          label,
          value,
          children,
        };
      });
      this.workPositionList = workPositionListd;
    },
    /**
     * 新增
     */
    updateCom({ row }) {
      const options = {
        materialCodeList: this.materialCodeList,
      };
      this.$emit("updateCom", {
        currentCom: "MaterialConfigAdd",
        mode: "add",
        row,
        options,
      });
    },
    /**
     * 所属工厂 change
     */
    handleFactoryChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val);
      this.resetWorkshop(this.formModel, "cascade");
      this.resetWarehouse(this.formModel, "cascade");
    },
    /**
     * 基础查询
     */
    handleBaseSearch(data) {
      this.doSearch({ ...data });
    },
    /**
     * 更多查询
     */
    handleMoreSearch(data) {
      if (data.workPositionId && data.workPositionId.length) {
        data.workPositionNextlabel = data.workPositionId;
        const workId = +data.workPositionNextlabel[1].split(",")[0];
        data.workPositionId = workId;
      }
      this.formModel = { ...data };
      this.doSearch({ ...data }, "getMoreList");
    },
    /**
     * 查询
     */
    async doSearch(params, api = "getMaterialConfigListByCodeOrName") {
      try {
        const { data, code } = await this.$httpService(
          this.$apiStore.materialManage(api, this.pageData),
          params
        );
        if (code) return;
        this.$store.commit(types.IS_LOADING, false);
        const {
          recordList = [],
          currentPage,
          pageSize,
          totalRecordCount: recordCount,
        } = data || {};
        this.pageData = { currentPage, pageSize, recordCount };
        // 列表数据格式化
        const list = recordList.map((item) => {
          return {
            ...item,
            workPositionTypeName: this.getWorkPositionTypeName(item),
          };
        });
        console.log(list);
        this.tableData = list || [];
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false);
      }
    },
    /**
     * 工位类型名称
     * @param {*} item
     */
    getWorkPositionTypeName(item) {
      return (
        this.materialTypsList?.find((t) => t.value === item.workPositionType)
          ?.label ?? ""
      );
    },
    /**
     * 编辑
     */
    editItem({ row }) {
      const options = {
        materialCodeList: this.materialCodeList,
      };
      this.$emit("updateCom", {
        currentCom: "MaterialConfigAdd",
        mode: "edit",
        row,
        options,
      });
    },
    /**
     * 删除
     */
    delItem({ row }) {
      console.log(row);
      this.$httpService(this.$apiStore.materialManage("delectMaterialConfig"), {
        id: row.id,
      }).then(({ code }) => {
        if (code !== 0) {
          return;
        }
        this.$message.success(this.$t("lang.gles.common.deleteSuccessfully"));
        if (this.tableData?.length < 2) {
          this.pageData.currentPage = 1;
        }
        this.doSearch(this.baseFormModel);
      });
    },
  },
};
</script>
<style scoped lang='scss'>
.basic-data-list {
  .operator-wrapper {
    justify-content: unset;
  }
}
</style>
